.aboutSection {
  width: 100rem;
}

.mainTitle {
  font-size: 4rem;
  text-shadow: 1px 1px black;
  font-family: "Bebas Neue", sans-serif;
  margin-left: 4rem;
}

.aboutBio {
  font-size: 1.5rem;
  width: 60rem;
  color: white;
  line-height: 3rem;
}

.aboutDetails {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profilePicture {
  border: #ffc755 0.25rem solid;
  border-radius: 25rem;
  background-color: whitesmoke;
  margin-left: 5rem;
  margin-top: 3.125rem;
}

span {
  color: #ffc755;
  font-family: "Bebas Neue", sans-serif;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .mainTitle {
    font-size: 3rem;
    text-shadow: 1px 1px black;
    font-family: "Bebas Neue", sans-serif;
    text-align: center;
    margin-bottom: -4rem;
    margin-left: 0;
  }
  .aboutSection {
    width: 100%;
  }

  .aboutDetails {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .aboutBio {
    font-size: 1.2rem;
    width: 75%;
    line-height: 2rem;
    margin-top: -4rem;
    text-align: center;
  }

  .profilePicture {
    margin: 0;
    transform: scale(0.5);
  }
}

@media all and (min-width: 480px) and (max-width: 768px) {
  .mainTitle {
    font-size: 3rem;
    text-shadow: 1px 1px black;
    font-family: "Bebas Neue", sans-serif;
    text-align: center;
    margin-bottom: -4rem;
    margin-left: 0;
  }
  .aboutSection {
    width: 100%;
  }

  .aboutDetails {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .aboutBio {
    font-size: 1rem;
    width: 75%;
    line-height: 2rem;
    margin-top: -4rem;
    text-align: center;
  }

  .profilePicture {
    margin: 0;
    transform: scale(0.5);
  }
}

@media all and (max-width: 480px) {
  .mainTitle {
    font-size: 3rem;
    text-shadow: 1px 1px black;
    font-family: "Bebas Neue", sans-serif;
    text-align: center;
    margin-bottom: -4rem;
    margin-left: 0;
  }
  .aboutSection {
    width: 100%;
  }

  .aboutDetails {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .aboutBio {
    font-size: 1rem;
    width: 75%;
    line-height: 2rem;
    margin-top: -4rem;
    text-align: center;
  }

  .profilePicture {
    margin: 0;
    transform: scale(0.5);
  }
}

/* iPad Portrait */
@media screen and (min-device-width: 481px) and (orientation: portrait) {
  .mainTitle {
    font-size: 3rem;
    text-shadow: 1px 1px black;
    font-family: "Bebas Neue", sans-serif;
    text-align: center;
    margin-bottom: -4rem;
    margin-left: 0;
  }
  .aboutSection {
    width: 100%;
  }

  .aboutDetails {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .aboutBio {
    font-size: 1.5rem;
    width: 75%;
    line-height: 2.5rem;
    margin-top: -4rem;
    text-align: center;
  }

  .profilePicture {
    margin: 0;
    transform: scale(0.5);
  }
}

/* iPad Landscape */
@media screen and (min-device-width: 481px) and (max-device-width: 1024) and (orientation: landscape) {
  .mainTitle {
    font-size: 3rem;
    text-shadow: 1px 1px black;
    font-family: "Bebas Neue", sans-serif;
    text-align: center;
    margin-bottom: -4rem;
    margin-left: 0;
  }
  .aboutSection {
    width: 100%;
  }

  .aboutDetails {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .aboutBio {
    font-size: 2rem;
    width: 75%;
    line-height: 4rem;
    margin-top: -4rem;
    text-align: center;
  }

  .profilePicture {
    margin: 0;
    transform: scale(0.5);
  }
}
