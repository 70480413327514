#workSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

#workHeader {
  font-size: 3.5rem;
  color: #ffc755;
  text-shadow: 1px 1px black;
  font-family: "Bebas Neue", sans-serif;
}

.jobHeader {
  font-size: 2rem;
  color: #ffc755;
  font-family: "Bebas Neue", sans-serif;
  text-align: center;
}

.employer {
  font-size: 1.5rem;
  margin-top: -1.5rem;
}

.jobDetails {
  font-size: 1.2rem;
  margin-top: -1.3rem;
}

.dutiesSection {
  margin-top: -1rem;
  font-size: 1.2rem;
  line-height: 2.5rem;
  width: 55rem;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
}

@media all and (min-width: 768px) and (max-width: 1024px) {
}

@media all and (min-width: 480px) and (max-width: 768px) {
  #workSection {
    padding-bottom: 2rem;
  }
  #workHeader {
    font-size: 3rem;
  }
  .dutiesSection {
    margin-top: -1rem;
    font-size: 1rem;
    line-height: 2.2rem;
    width: 30rem;
  }
  .employer {
    text-align: center;
    font-size: 1.3rem;
  }
  .jobDetails {
    text-align: center;
    font-size: 1.1rem;
  }
}

@media all and (max-width: 480px) {
  #workSection {
    padding-bottom: 2rem;
  }
  #workHeader {
    font-size: 3rem;
  }
  .dutiesSection {
    margin-top: -1rem;
    font-size: 1rem;
    line-height: 2.2rem;
    width: 20rem;
  }
  .employer {
    text-align: center;
    font-size: 1.3rem;
  }
  .jobDetails {
    text-align: center;
    font-size: 1.1rem;
  }
}

/* iPhone Portrait */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
}

/* iPad Portrait */
@media screen and (min-device-width: 481px) and (orientation: portrait) {
  #workSection {
    padding-bottom: 2rem;
  }
  #workHeader {
    font-size: 3rem;
  }
  .dutiesSection {
    margin-top: -1rem;
    font-size: 1rem;
    line-height: 2.2rem;
    width: 40rem;
  }
  .employer {
    text-align: center;
    font-size: 1.3rem;
  }
  .jobDetails {
    text-align: center;
    font-size: 1.1rem;
  }
}
