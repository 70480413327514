.contactSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactHeader {
  font-size: 3.5rem;
  color: #ffc755;
  text-shadow: 1px 1px black;
  font-family: "Bebas Neue", sans-serif;
}

.contactLinks {
  display: flex;
  gap: 4rem;
  margin-bottom: 5rem;
}

.contactLinks img {
  height: 5rem;
}

.contactLinks img:hover {
  transform: scale(1.2);
  transition: ease all 250ms;
}

@media all and (min-width: 480px) and (max-width: 768px) {
  .contactHeader {
    font-size: 3rem;
  }

  .contactLinks {
    gap: 1rem;
    margin-bottom: 2rem;
  }
}

@media all and (max-width: 480px) {
  .contactHeader {
    font-size: 3rem;
  }

  .contactLinks {
    gap: 1rem;
    margin-bottom: 2rem;
  }
}
