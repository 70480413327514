* {
  background-color: #404258;
  font-family: "Lato", sans-serif;
}

main {
  height: 100vh;
}

.mainTitle {
  color: white;
}

.mainContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
}

@media all and (min-width: 768px) and (max-width: 1024px) {
}

@media all and (min-width: 480px) and (max-width: 768px) {
   main {
    width: 100%;
  }
}

@media all and (max-width: 480px) {
  main {
    width: 100%;
  }
}
