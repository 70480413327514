#projectsSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100rem;
}

#projectsHeader {
  font-size: 3.5rem;
  color: #ffc755;
  font-family: "Bebas Neue", sans-serif;
}

#projectsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 50%;
}

.projectTitle {
  font-size: 1.8rem;
  color: white;
}

.projectThumbnail {
  max-width: 100%;
  height: auto;
  width: auto\9;
  border-radius: 1.25rem;
}

.projectDescription {
  font-size: 1rem;
  color: white;
  line-height: 2rem;
  width: 31.25rem;
  text-align: center;
}

.projectLink {
  text-decoration: none;
  font-size: 1.1rem;
  color: white;
  border: 0.125rem #ffc755 solid;
  padding: 0.625rem;
  border-radius: 1.5625rem;
  text-shadow: 1px 1px black;
  width: 6.25rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.125rem;
}

.projectLink:hover {
  background-color: #ffc755;
  transition: ease 500ms;
  color: #404258;
  text-shadow: none;
  cursor: pointer;
}

#toolsHeader {
  font-size: 2rem;
  color: #ffc755;
  text-shadow: 1px 1px black;
  font-family: "Bebas Neue", sans-serif;
}

#iconsContainer {
  display: grid;
  grid-template: repeat(1, 1fr) / repeat(1, 1fr);
  grid-auto-flow: column;
  gap: 20px;
  margin-bottom: 4rem;
}

#armBrainThumbnail {
  max-width: 20rem;
}

.softwareIcon {
  height: 48px;
  width: 48px;
}

.softwareIcon:hover {
  transform: scale(1.2);
  transition: ease all 100ms;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  #projectsContainer {
    flex-direction: column;
    padding: 0 0;
    margin-top: -2rem;
  }
}

@media all and (min-width: 480px) and (max-width: 768px) {
  #projectsSection {
    width: 100%;
  }
  #projectsHeader {
    font-size: 3rem;
  }

  .projectTitle {
    text-align: center;
    font-size: 2rem;
  }
  #projectsContainer {
    flex-direction: column;
    padding: 0 0;
    margin-top: -2rem;
  }
  .projectDescription {
    font-size: 1rem;
    color: white;
    line-height: 2rem;
    width: 16rem;
    text-align: center;
  }
  #iconsContainer {
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(1, 1fr);
    grid-auto-flow: column;
    gap: 20px;
    margin-bottom: 4rem;
  }
}

@media all and (max-width: 480px) {
  #projectsSection {
    width: 100%;
  }
  #projectsHeader {
    font-size: 3rem;
  }
  .projectTitle {
    text-align: center;
    font-size: 2rem;
  }
  #projectsContainer {
    flex-direction: column;
    padding: 0 0;
    margin-top: -2rem;
  }

  .projectDescription {
    font-size: 1rem;
    color: white;
    line-height: 2rem;
    width: 16rem;
    text-align: center;
  }
  #iconsContainer {
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(1, 1fr);
    grid-auto-flow: column;
    gap: 1.25rem;
    margin-bottom: 4rem;
  }
}

/* iPhone Portrait */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
}

/* iPhone Landscape */
@media screen and (max-device-width: 480px) and (orientation: landscape) {
}

/* iPad Portrait */
@media screen and (min-device-width: 481px) and (orientation: portrait) {
  #projectsContainer {
    flex-direction: column;
  }
  #iconsContainer {
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(1, 1fr);
    grid-auto-flow: column;
    gap: 1.25rem;
    margin-bottom: 4rem;
  }
}

/* iPad Landscape */
@media screen and (min-device-width: 481px) and (max-device-width: 1024) and (orientation: landscape) {
  #projectsContainer {
    flex-direction: column;
  }
  #iconsContainer {
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(1, 1fr);
    grid-auto-flow: column;
    gap: 1.25rem;
    margin-bottom: 4rem;
  }
}
