form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 2.5rem;
}

form label {
  color: white;
  font-size: 1.5rem;
}

form input {
  background-color: white;
  font-size: 1rem;
}

form textarea {
  background-color: white;
  font-size: 1rem;
  border-radius: 0.75rem;
}

.nameBox,
.emailBox,
.subjectBox {
  width: 16rem;
  border-radius: 0.75rem;
}

.nameBox:focus,
.emailBox:focus,
.subjectBox:focus,
textarea:focus {
  box-shadow: 0 0 0 4px #ffc755;
  transition: ease all 250ms;
}

.messageBox {
  width: 16rem;
  height: 100px;
}

.sendButton {
  color: white;
  border: 2px #ffc755 solid;
  padding: 10px;
  border-radius: 25px;
  text-shadow: 2px 2px black;
  background-color: #404258;
  height: 50px;
  width: 200px;
  align-self: center;
  font-size: 1.3rem;
  letter-spacing: 0.125rem;
}

.sendButton:hover {
  background-color: #ffc755;
  transition: ease 500ms;
  color: #404258;
  text-shadow: none;
  cursor: pointer;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
}

@media all and (min-width: 768px) and (max-width: 1024px) {
}

@media all and (min-width: 480px) and (max-width: 768px) {
  form label {
    color: white;
    font-size: 1rem;
  }
  form {
    padding-bottom: 2.5rem;
  }
}

@media all and (max-width: 480px) {
  form label {
    color: white;
    font-size: 1rem;
  }
  form {
    padding-bottom: 2.5rem;
  }
}

/* iPad Landscape */
@media screen and (min-device-width: 481px) and (max-device-width: 1024) and (orientation: landscape) {
  form {
    padding-bottom: 2.5rem;
  }
}
